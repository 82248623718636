<template>
  <div class="workDetail">
    <Nav></Nav>
    <li class="con" v-for="(item, index) in list" :key="index">
      <div class="img" v-if="index == 0">
        <img v-lazy="item.url" alt=""/>
      </div>
      <div class="imgRow" v-if="index == 1">
        <img v-lazy="item.url1" alt=""/>
        <img v-lazy="item.url2" alt=""/>
      </div>
      <div class="text" v-if="index == 0">
        <div class="left">
          <div class="title">Daogrs</div>
          <p class="chinese"></p>
          <p class="chinese-min"> 体验纯正意大利设计美学带来的震撼</p>
          <p class="english">
            
          </p>
        </div>
        <div class="right" >
          <p><span>Creative Director</span>：Hope</p>
          <p><span>Photographer</span>：小露</p>
        </div>
      </div>
      <!-- <div class="text" v-if="index == 1">
      <div class="left">
      <p class="chinese-min"></p>
      </div>
      </div> -->
    </li>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "../components/Nav";
import Footer from  '../components/FooterInside.vue'
export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      list: [
          {
              url: require('../assets/work-10/x1.jpg')
          },
          {
              url1: require('../assets/work-10/x2.jpg'),
              url2: require('../assets/work-10/x1.jpg')
          }
      ],
    };
  },
};
</script>

<style scoped lang="less">
/* 宽度大于1300 */

.workDetail {
  .con {
    width: 100%;
    .img {
      display: flex;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .imgRow {
      display: flex;
      width: 100%;
      img {
        width: 50%;
      }
    }
    .text {
      display: flex;
      justify-content: space-between;
      padding: 0.3rem;
      .left {
        width: 700px;
        .title {
          font-size: 26px;
          margin-bottom:20px;
        }
        .chinese {
          font-size: 20px;
          line-height: 0px;
          margin-bottom: 0px;
        }
        .chinese-min{
          font-size: 15px;
          line-height: 26px;
        }
        .english {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .right {
        margin-top: 43px;
        margin-right: 0.3rem;
        font-size: 14px;
        span{
          display: inline-block;
          width:120px;
        }
        p {
          line-height: 12px;
        }
      }
    }
  }
}
/* 宽度大于1100并且小于1300 */
@media only screen and (min-width:1100px) and (max-width:1300px) {
      .text {
      .left {
        width: 680px !important;
        }
        }

}
/* 宽度大于870并且小于1100 */
@media only screen and (min-width:870px) and (max-width:1100px) {

    .text {
      .left {
        width: 530px !important;
        }
        }
}
// /* 宽度小于870 */
@media only screen and (max-width: 870px){
    .workDetail {
    .con {
        .img {
        width: 100%;
        }
        .text {
        display: flex;
        flex-direction:column;
        align-items: center;
        margin: 30px 0;
        .left {
            width:96%;
            .title {
            margin-left:0px;
            font-size: 26px;
            margin-bottom: 20px;
            }
            .chinese {
            font-size: 13px;
            line-height: 20px!important;
            }
            .english {
            font-size: 13px;
            line-height: 20px!important;
            }
        }
        .right {
            width:96%;
            padding: 0!important;
            margin: 0!important;
            padding-left:0!important;
            font-size: 13px;
            p {
            line-height: 10px;
            }
        }
        }
    }
    }
}
</style>